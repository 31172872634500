document.addEventListener( "DOMContentLoaded", function() {

  // *** GENERAL VARIABLES
  var win = window;

  // *** SLIDERS CONTROLS
  var hpSwiper = new Swiper('.swiper-hpSlider', {
      speed: 1000,
      autoplay: 1500,
      pagination: '.swiper-pagination',
      paginationClickable: true,
      effect: 'fade'
  });

  var userSwiper = new Swiper('.sectionUserReview__slider', {
      speed: 800,
      pagination: '.swiper-pagination',
      paginationClickable: true,
      effect: 'slide',
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev'
  });

  // *** UTILITY FUNCTIONS
  // REMOVE CLASS
  function removeClass(elem, classes) {
    var matchPattern = new RegExp('\\s?' + classes, 'g');
    var classList = elem.className;
    var hasClass = classList.match(classes);
    if (hasClass) {
      var removeClasses = classList.toString().replace(matchPattern, '');
      elem
        .className = removeClasses;
    } else { return false; }
  }

  // SEARCH PARENTS
  function findParents(el, cls) {
    while (el.parentNode) {
        el = el.parentNode;
        if (el.classList.contains(cls))
            return el;
    }
    return null;
  }

  // SCROLL
  function scroll(toElement, speed) {
    var windowObject =  window,
        windowPos =     windowObject.pageYOffset,
        pointer =       toElement.getAttribute('href').slice(3),
        elem =          document.getElementById(pointer),
        elemOffset =    elem.offsetTop;
    var counter = setInterval(function() {
      windowPos;
      if (windowPos > elemOffset) { //bottom to top
        windowObject
          .scrollTo(0, windowPos);
        windowPos-=speed;
        if (windowPos <= elemOffset) { // cancel scrolling
          clearInterval(counter);
          windowObject
            .scrollTo(0, elemOffset+1);
        }
      } else { //top to bottom
        windowObject
          .scrollTo(0, windowPos);
        windowPos+=speed;
        if (windowPos >= elemOffset) { // cancel scrolling
          clearInterval(counter);
          windowObject
            .scrollTo(0, elemOffset+2);
        }
      }
    }, 15);
  }

  // *** EVENT FUNCTIONS
  // SLIDE TOGGLE
  function rollContent(ths) {
    if (ths.classList.contains('active')) {
      ths
        .classList
        .remove('active');
    }
    else {
      ths
        .classList
        .add('active');
    }
  }

  // POPUP
  function popup(ths) {
    var popup = ths.nextSibling;
    popup
      .style.display = 'block';
  }

  // POPUP CLOSE
  function popupHide(ths) {
    var popup = findParents(ths, 'popup');
    popup.style.display = 'none';
  }

  // POPUP HEIGHT OVERFLOW CALC
  function popupHeight(content, parentBox) {
    var optimalHeight = win.innerHeight - 120;
    for (var i = 0; i < content.length; i++) {
      var contentWrapper = findParents(content[i], parentBox);
      if (content[i].offsetParent !== null) {
        if (content[i].offsetHeight > optimalHeight) {
          contentWrapper
            .style.overflowY = 'scroll';
        }
        else {
          contentWrapper
            .style.overflow = 'visible';
        }
      }
    }
  }

  // SUBSTRING FAQ ANSWER
  function substringAnswer(ths) {
    var string = ths.innerText,
        substr = string.substring(0, 125);
    if (string.length > 125) {
      ths
        .innerText = substr + '... ';
    }
    else {
      ths
        .nextSibling.style.display = 'none';
    }
  }

  function unsubstringAnswer(ths, orig, el) {
    var sibling =   ths.previousSibling,
        origArray = orig,
        elem =      el;
    for (i = 0; i < elem.length; i++) {
      if (elem.indexOf(sibling) === i) {
        sibling
          .innerText = orig[i];
        ths
          .style.display = 'none';
      }
    }
  }

  // *** EVENTS
  // RESPONSIVE NAVBAR
  var collapse = document.getElementById('js-collapse'),
      navbar =  document.getElementById('js-navbar'),
      navbarList =  document.getElementById('js-navbarList');

  collapse
    .addEventListener('click', function() {
      rollContent(navbarList);
    }, false);

  window
    .addEventListener('scroll', function() {
      if (window.innerWidth <= 750 && window.pageYOffset > 68) {
        navbar.style.position = 'fixed';
      }
      else {
        navbar.style.position = 'relative';
      }
    });

  // SLIDE TOGGLE
  var roller = document.getElementsByClassName('roller__arrow');

  for (i = 0; i < roller.length; i ++) {
    if (roller) {
      roller[i]
        .addEventListener('click', function() {
          rollContent(this);
        }, false);
    }
  }

  // POPUPS
  var popupButtons =        document.getElementsByClassName('js-popupInit'),
      popupClose =          document.getElementsByClassName('popup__close'),
      content =             document.getElementsByClassName('popup__overflow'),
      box =                 document.getElementsByClassName('popup__box');

  // show + calc height for overflow
  if (popupButtons) {
    for (var i = 0; i < popupButtons.length; i++) {
      popupButtons[i]
        .addEventListener('click', function() {
          popup(this);
          popupHeight(content, 'popup__content');
        }, false);
    }
  }

  // close
  for (i = 0; i < popupClose.length; i ++) {
    if (popupClose) {
      popupClose[i]
        .addEventListener('click', function() {
          popupHide(this);
        }, false);
    }
  }

  // calc height for overflow
  window.addEventListener('resize', function() {
    popupHeight(content, 'popup__content');
  }, false);

  popupHeight(content, 'popup__content');

  // PAGE SCROLL
  var scrollInitEl = document.getElementsByClassName('js-scroller');

  for (var i = 0; i < scrollInitEl.length; i++) {
    scrollInitEl[i]
      .addEventListener('click', function() {
        scroll(this, 200);
      }, false);
  }
});
